/*! Michael Pumo - helpers.js */

function domReady (fn) {
  if (document.readyState !== 'loading') {
    fn()
    return
  }
  document.addEventListener('DOMContentLoaded', fn)
}

function pageLoaded (fn) {

  const isComplete = () => document.readyState === 'complete'
  
  const runIfComplete = () => {
    if (isComplete()) {
      document.removeEventListener('readystatechange', runIfComplete)
      fn()
    }
  }
  
  if (isComplete()) {
    fn()
    return
  }
  
  document.addEventListener('readystatechange', runIfComplete)

}

function shuffle (array) {
  let i = 0
  let j = 0
  let temp = null

  for (i = array.length - 1; i > 0; i -= 1) {
    j = Math.floor(Math.random() * (i + 1))
    temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }

  return array
}

function randomNumber (from, to) {
  return Math.floor(Math.random() * to) + from
}

function randomDouble (from, to) {
  return (Math.random() * to) + from
}

function isElementInViewport (el, partial = false) {
  const rect = el.getBoundingClientRect()

  if (partial) {
    return (
      rect.top >= 0 &&
      rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.left >= 0
    )
  }

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

export {
  shuffle,
  randomDouble,
  randomNumber,
  domReady,
  isElementInViewport,
  pageLoaded
}
