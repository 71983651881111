function bp (s, callback) {
  const size = s.trim()
  const sizes = {
    small: '576px',
    medium: '768px',
    large: '992px',
    xlarge: '1200px'
  }
  
  if (sizes.hasOwnProperty(size)) {
    return window
      .matchMedia(`(min-width: ${sizes[size]})`)
      .addListener(callback)
  }
  
  throw new ReferenceError(`The size ${size} is not a valid breakpoint: ${JSON.stringify(sizes)}`)
}

export { bp }