import { bp } from "../../utilities/breakpoints";

function init() {
  const navigation = document.querySelector(".c-nav-primary");

  if (!navigation) {
    return;
  }

  const button = navigation.querySelector(".c-nav-primary__hamburger");

  if (!button) {
    return;
  }

  bp("medium", () => {
    navigation.classList.remove("is-active");
    document.body.classList.remove("has-menu");
  });

  button.addEventListener("click", () => {
    navigation.classList.toggle("is-active");
    document.body.classList.toggle("has-menu");
  });
}

export default init;
