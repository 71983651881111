function init () {
  const elements = document.querySelectorAll('[data-inview]')

  if (!elements.length) {
    return
  }

  const options = {
    threshold: 0.1,
  }

  Array.from(elements).forEach(element => observer(options).observe(element))
}

function callback (entries, observer) {
  Array.from(entries).forEach(entry => {
    if (isIntersecting(entry, observer.thresholds[0])) {
      entry.target.classList.add('is-visible')
    }
  })
}

function observer (options) {
  return new IntersectionObserver(callback, options)
}

function isIntersecting(entry, threshold) {
  return entry.intersectionRatio >= threshold
}

export default init
