import Flickity from "flickity";

function init() {
  const element = document.querySelector(".c-carousel");

  if (!element) {
    return;
  }

  const flkty = new Flickity(element, {
    wrapAround: true,
    pageDots: false,
    autoPlay: 5000,
    adaptiveHeight: false,
    setGallerySize: false,
    arrowShape: "M72.6,101 22.6,51 72.6, 1"
  });
}

export default init;
