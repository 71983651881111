import Plyr from 'plyr'
import fit from 'fit.js'

function init () {
  const player = document.querySelector('.c-showreel__plyr')

  if (!player) {
    return
  }

  const plyr = new Plyr(player, {
    keyboard: {
      focused: false,
      global: false
    }
  })

  plyr.on('ready', event => {
    const { wrapper, container } = event.detail.plyr.elements
    // Scaling
    fit(wrapper, container, {
      hAlign: fit.CENTER,
      vAlign: fit.CENTER,
      cover: false,
      watch: true,
      apply: true
    })
  })

  plyr.on('playing', () => {
    document.body.classList.add('is-showreel-playing')
  })

  plyr.on('pause', () => {
    document.body.classList.remove('is-showreel-playing')
  })
}

export default init
