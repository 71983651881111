import Masonry from 'masonry-layout'
import baguetteBox from 'baguettebox.js'
import Plyr from 'plyr'

const initGrid = {
  images: () => {
    const imageGrid = document.querySelector('[data-grid="images"]')

    if (!imageGrid) {
      return
    }

    let imageMsnry

    const tabs = document.querySelector('.c-media-switch')
    tabs.addEventListener('bp-tabSwitched', e =>
      e.detail === 'images' ? create() : destroy()
    )

    function create () {
      imageMsnry = new Masonry(imageGrid, {
        itemSelector: '.c-image-grid__image, .c-image-grid__quote',
        columnWidth: '.grid-sizer',
        gutter: 15,
        percentPosition: true,
        transitionDuration: 0
      })
    }

    function destroy () {
      if (imageMsnry) {
        imageMsnry.destroy()
        imageMsnry = false
      }
    }

    create()
  },
  press: () => {
    const pressGrid = document.querySelector('[data-grid="press"]')

    if (!pressGrid) {
      return
    }

    let pressMsnry

    const tabs = document.querySelector('.c-media-switch')
    tabs.addEventListener('bp-tabSwitched', e =>
      e.detail === 'press' ? create() : destroy()
    )

    function create () {
      pressMsnry = new Masonry(pressGrid, {
        itemSelector: '.c-press-grid__item',
        columnWidth: '.grid-sizer',
        gutter: 15,
        percentPosition: true,
        transitionDuration: 0
      })
    }

    function destroy () {
      if (pressMsnry) {
        pressMsnry.destroy()
        pressMsnry = false
      }
    }

    create()
  },
  videos: () => {
    const videos = document.querySelectorAll('.c-video-grid__plyr')

    if (!videos.length) {
      return
    }

    let players

    // const tabs = document.querySelector('.c-media-switch')
    // tabs.addEventListener("bp-tabSwitched", e =>
    //   e.detail === "videos" ? create() : destroy()
    // );

    function create () {
      players = Array.from(videos).map(x => {
        return new Plyr(x, {
          keyboard: {
            focused: false,
            global: false
          }
        })
      })

      function getPlayerParent ({ elements }) {
        return elements.container.parentNode.parentNode
      }

      players.forEach((player, i) => {
        const parent = getPlayerParent(player)
        if (i === 0) {
          parent.classList.add('is-active')
        }
        player.elements.container.addEventListener('click', event => {
          if (!parent.classList.contains('is-active')) {
            players.forEach(other => {
              other.stop()
              getPlayerParent(other).classList.remove('is-active')
            })
            parent.classList.add('is-active')
            player.play()
            player.volume = 1
          }
        })
      })
    }

    // function destroy () {
    //   if (players.length) {
    //     players.forEach(x => x.destroy())
    //   }
    // }

    create()
  }
}

function initLightbox () {
  const imageGrid = document.querySelector('.c-image-grid')
  if (imageGrid) {
    baguetteBox.run('.c-image-grid')
  }

  const pressGrid = document.querySelector('.c-press-grid')
  if (pressGrid) {
    baguetteBox.run('.c-press-grid')
  }
}

function initTabSwitcher () {
  const tabContainer = document.querySelector('.c-media-switch')
  const tabs = document.querySelectorAll('.c-media-switch__tab')
  const grids = document.querySelectorAll(`[data-grid]`)

  if (!tabContainer || !tabs.length || !grids.length) {
    return
  }

  // Array.from(tabs).forEach(x => {
  //   const gridType = x.getAttribute('data-grid')
  //   initGrid(gridType)
  // })

  function switchTab (gridType) {
    Array.from(tabs).forEach(x => x.classList.remove('is-active'))
    Array.from(grids).forEach(x => x.classList.remove('is-active'))

    const nextGrid = document.querySelector(`[data-grid="${gridType}"]`)
    const nextTab = document.querySelector(`[data-switch="${gridType}"]`)
    nextTab.classList.add('is-active')
    nextGrid.classList.add('is-active')

    // initGrid[gridType]();
    const event = new CustomEvent('bp-tabSwitched', { detail: gridType })
    document.querySelector('.c-media-switch').dispatchEvent(event)
  }

  tabContainer.addEventListener('click', ({ target }) => {
    if (!target.classList.contains('c-media-switch__tab')) return
    if (target.classList.contains('is-active')) return
    const gridType = target.getAttribute('data-switch')
    switchTab(gridType)
  })

  const firstGridType = tabs[0].getAttribute('data-switch')
  switchTab(firstGridType)
}

function init () {
  Object.keys(initGrid).forEach(x => initGrid[x]())
  initTabSwitcher()
  initLightbox()
}

export default init
