// Libraries
// import svg4everybody from 'svg4everybody';

// Modules
// import accordion from './modules/accordion'
import carousel from './modules/carousel'
import inView from './modules/in-view'
import lazyLoad from './modules/lazyload'
import navigation from './modules/navigation'
import portfolio from './modules/portfolio'
import scrolled from './modules/scrolled'
import showreel from './modules/showreel'

// Initialize
function init () {
  // svg4everybody();
  // accordion()
  carousel()
  inView()
  lazyLoad()
  navigation()
  portfolio()
  scrolled()
  showreel()
}

export default init
