// CSS
import '../scss/app.scss'

// SVG
import '../svg/close.svg'
import '../svg/facebook.svg'
import '../svg/instagram.svg'
import '../svg/logo.svg'
import '../svg/twitter.svg'
import '../svg/vimeo.svg'

// JS
import { domReady } from './utilities/helpers'
import framework from './framework'

function start () {
  framework()
}

domReady(start)
