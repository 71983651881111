import lazySizes from 'lazysizes'

function init () {
  
  lazySizes.cfg.loadMode = 2
  lazySizes.cfg.expand = 300

}

export default init
